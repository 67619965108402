import iataCalculator from '@/services/iata-calculator';
import { reverseTransform as reverseAddressTransform } from '@/services/address-transformer';
import * as moment from 'moment';
import { getSupportedLocale } from '@/config/i18n';

export function getAddressesFromApi(data, type) {
    const addresses = [];

    data.forEach((address) => {
        addresses.push({
            type,

            id: address.id,
            kontaktadresse_id: address.kontaktadresse_id,

            name: address.firma,
            contact: address.kontakt,

            address: address.adresse,
            zip: address.plz,
            location: address.ort,
            phone: address.phone,

            datetime: address.datetime ? moment.default(address.datetime).toDate() : null,

            timeStart: address.time_start,
            timeEnd: address.time_end,
        });
    });

    return addresses;
}

export function getStateName(shipment) {
    const { state, cancelled } = shipment;

    if (cancelled) {
        return 'cancelled';
    }

    if (state < 300) {
        return 'created';
    }

    if (state === 300) {
        return 'pickedUp';
    }

    if (state < 1000) {
        return 'inDelivery';
    }

    return 'delivered';
}

export function isEditable(shipment) {
    return shipment.state < 300;
}

function prefillColisObject(element) {
    return {
        notes: element.description,
        length: element.length,
        weight: element.weight,
        height: element.height,
        width: element.width,
    };
}

export function transformFull(shipment) {
    const from = getAddressesFromApi(shipment.kontaktadressen.send, 'from');
    const to = getAddressesFromApi(shipment.kontaktadressen.receive, 'to');

    const colis = [];
    if (shipment.colis.length) {
        shipment.colis.forEach((element) => {
            if (element.count > 1) {
                for (let i = 0; i < element.count; i += 1) {
                    colis.push(prefillColisObject(element));
                }
            } else {
                colis.push(prefillColisObject(element));
            }
        });
    }

    return {
        key: shipment.id,
        externalNumber: shipment.formatted_number,
        reference: shipment.rechnungsbeschreibung_offiziell,
        state: shipment.state,
        cancelled: shipment.cancelled,

        // Addresses
        from,
        to,

        // Colis
        numberOfColis: shipment.coli_count,
        totalWeight: shipment.total_weight,
        colis,

        // Prices
        priceExclVat: shipment.preis_netto ? parseFloat(shipment.preis_netto).toFixed(2) : null,
        priceInclVat: shipment.preis_brutto ? parseFloat(shipment.preis_brutto).toFixed(2) : null,

        // Visier
        visierName: shipment.visiert_von,

        // Tour
        isTour: shipment.tourenplan_aktiv,

        // Pickup & Delivery
        pickedUp: shipment.pickup_datetime ? moment.default(shipment.pickup_datetime).toDate() : null,
        delivered: shipment.visier_date ? moment.default(`${shipment.visier_date} ${shipment.visier_time}`).toDate() : null,

        // Pickup & Delivery window
        pickupFrom: shipment.pickup_from ? moment.default(shipment.pickup_from).toDate() : null,
        pickupTo: shipment.pickup_to ? moment.default(shipment.pickup_to).toDate() : null,
        deliveryFrom: shipment.drop_from ? moment.default(shipment.drop_from).toDate() : null,
        deliveryTo: shipment.drop_to ? moment.default(shipment.drop_to).toDate() : null,

        // Invoice
        invoiceType: shipment.invoice_type,
        invoiceEmailAddresses: shipment.rechnung_email_adressen,
    };
}

export function transform(shipment) {
    const from = getAddressesFromApi(shipment.addresses.from, 'from');
    const to = getAddressesFromApi(shipment.addresses.to, 'to');

    return {
        key: shipment.id,
        externalNumber: shipment.formatted_number,
        reference: shipment.rechnungsbeschreibung_offiziell,
        state: shipment.state,
        cancelled: shipment.cancelled,

        // Dates
        start: moment.default(shipment.start.date).toDate(),
        end: moment.default(shipment.end.date).toDate(),

        // Addresses
        from,
        to,

        // Colis
        numberOfColis: shipment.coli_count,
        totalWeight: shipment.total_weight,

        // Visier
        visierName: shipment.visiert_von,

        // Tour
        isTour: shipment.tourenplan_aktiv,

        // Pickup & Delivery
        pickedUp: shipment.pickup_datetime ? moment.default(shipment.pickup_datetime).toDate() : null,
        delivered: shipment.visier_date ? moment.default(`${shipment.visier_date} ${shipment.visier_time}`).toDate() : null,

        // Pickup & Delivery window
        pickupFrom: shipment.pickup_from ? moment.default(shipment.pickup_from).toDate() : null,
        pickupTo: shipment.pickup_to ? moment.default(shipment.pickup_to).toDate() : null,
        deliveryFrom: shipment.drop_from ? moment.default(shipment.drop_from).toDate() : null,
        deliveryTo: shipment.drop_to ? moment.default(shipment.drop_to).toDate() : null,

        // Transport method
        transportart_id: shipment.transportart_id,
    };
}

export function reverseTransform(form, user, locale) {
    const colis = [];

    form.colis.colis.forEach((coli) => {
        colis.push({
            description: coli.notes,
            weight: coli.weight,
            count: 1,
            height: coli.height,
            width: coli.width,
            length: coli.length,
            iata: iataCalculator.calculate(coli.length, coli.height, coli.width),
        });
    });

    const sender = reverseAddressTransform(form.sender, false);
    const receiver = reverseAddressTransform(form.receiver, false);
    const biller = reverseAddressTransform(form.invoiceAddress, true);

    const date = moment.default(form.date);
    const time = moment.default(form.time, 'HHmm');

    return {
        is_offer: null,
        transportart_id: 3,
        website_offertbetrag: 0,

        start_date: date.format('YYYY-MM-DD'),
        start_time: time.format('HH:mm:ss'),

        rechnungsbeschreibung_offiziell: form.invoiceReference,

        auftrag_kuriere: [],
        kontaktadressen: {
            send: [
                sender,
            ],
            receive: [
                receiver,
            ],
            third: [
                biller,
            ],
        },

        colis,

        frachtbrief: {
            fb_language: getSupportedLocale(locale),
        },

        note: form.notes,
        send_pod: form.emailClient,

        // Dritte
        rechnungssteller: 3,

        // Invoice
        invoice_type: form.invoiceType,

        // Emails
        empfaenger_email: form.emailClient,
        sender_email: form.emailLetter,
        cancellation_emails: form.emailClient,
        billing_emails: form.invoiceMail,

        // Partner
        partner: 'Rosalina',
    };
}

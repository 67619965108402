import { getStateName } from '@/services/shipment-transformer';
import * as moment from 'moment';

export function getFbNumber(shipment) {
    let externNr = shipment.extern_nr || '';
    const couriers = shipment.auftrag_kuriere;

    if (Array.isArray(couriers)) {
        const courier = couriers[0];

        externNr = `${courier.kurier.kundennummer}-${externNr}`;
    }

    return externNr;
}

export function getVisierData(shipment) {
    const name = shipment.visiert_von ? shipment.visiert_von : null;
    const date = shipment.visier_datum ? moment.default(shipment.visier_datum).toDate() : null;

    return { name, date };
}

function getShipmentAddress(shipment, key) {
    const addresses = [];

    if (!shipment.kontaktadressen[key] || !Array.isArray(shipment.kontaktadressen[key])) {
        return '';
    }

    shipment.kontaktadressen[key].forEach((address) => {
        addresses.push(`${address.plz} ${address.ort}`);
    });

    return addresses.join(', ');
}

function getShipmentDate(shipment, key) {
    if (!shipment[key]) {
        return null;
    }

    return moment.default(shipment[key]).toDate();
}

export function getFrom(shipment) {
    return getShipmentAddress(shipment, 'send');
}

export function getTo(shipment) {
    return getShipmentAddress(shipment, 'receive');
}

export function getStart(shipment) {
    return moment.default(`${shipment.start_date} ${shipment.start_time}`).toDate();
}

export function getEnd(shipment) {
    return moment.default(`${shipment.end_date} ${shipment.end_time}`).toDate();
}

export function getStateList(shipment, state) {
    const list = {
        created: false,
        readyForPickup: false,
        pickedUp: false,
        inDelivery: false,
        delivered: false,
    };

    if (state === 'cancelled') {
        return list;
    }

    const keys = Object.keys(list);

    for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];

        list[key] = true;

        if (key === state) {
            return list;
        }
    }

    return list;
}

export default function transform(shipment) {
    const state = getStateName(shipment);
    const visierung = getVisierData(shipment);

    return {
        // List
        list: getStateList(shipment, state),

        // Locations
        from: getFrom(shipment),
        to: getTo(shipment),

        // Times
        pickup: getShipmentDate(shipment, 'pickup_datetime'),
        delivery: shipment.visier_date ? moment.default(`${shipment.visier_date} ${shipment.visier_time}`).toDate() : null,
        start: getStart(shipment),
        end: getEnd(shipment),

        // Frachtbrief
        fbNumber: getFbNumber(shipment),

        // Visierung
        visierDate: visierung.date,
        visierName: visierung.name,

        // State
        state,
        cancelled: state === 'cancelled',
    };
}
